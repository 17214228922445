<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl"
        ><v-card-text class="px-0 py-0">
          <v-row class="pa-4 ms-3">
            <v-col cols="4">
              <v-row class="tittle__row">
                <label class="text-md font-weight-bolder ms-1 mandatory ml-2"
                  >Mode Of Transport</label
                >
              </v-row>
              <v-row>
                <label class="text-lg text-typo ms-1 label__value">{{
                  mode_of_transport
                }}</label>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row class="tittle__row">
                <label class="text-md font-weight-bolder ms-1 mandatory ml-2"
                  >Vehicle NO</label
                >
              </v-row>
              <v-row>
                <label class="text-lg text-typo ms-1 label__value">{{
                  vehicle_no
                }}</label>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row class="tittle__row">
                <label class="text-md font-weight-bolder ms-1 mandatory ml-2"
                  >Driver Contact No</label
                >
              </v-row>
              <v-row>
                <label class="text-lg text-typo ms-1 label__value">{{
                  driver_contact_no
                }}</label>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="card-padding">
          <v-form ref="frmSearch">
            <v-container class="px-0">
              <v-row>
                <v-col offset="10">
                  <v-btn
                    @click="back"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    color="blue"
                    class="
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      ml-2
                    "
                    >Back</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1">
                    Ref</label
                  >

                  <v-text-field
                    v-model="ref"
                    placeholder="Enter Ref"
                    hide-details="auto"
                    :readonly="type == 'VIEW' ? true : false"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Date</label
                  >

                  <v-menu
                    ref="mnu_date"
                    v-model="mnu_date"
                    :close-on-content-click="false"
                    :return-value.sync="mnu_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                          mt-5
                        "
                        dense
                        flat
                        filled
                        solo
                        height="43"
                        placeholder="Date"
                        persistent-hint
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="green lighten-1"
                      header-color="primary"
                      v-model="date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="mnu_date = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.mnu_date.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Name Of Supplier</label
                  >

                  <v-text-field
                    v-model="supplier_name"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    placeholder="Enter Name Of Supplier"
                    persistent-hint
                    :readonly="type == 'VIEW' ? true : false"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Bill No</label
                  >

                  <v-text-field
                    v-model="bill_no"
                    placeholder="Enter Bill No"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    persistent-hint
                    :readonly="type == 'VIEW' ? true : false"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >No: Of Bags</label
                  >

                  <v-text-field
                    placeholder="Enter No: Of bags"
                    :rules="[(v) => !!v || 'No: Of bags is required']"
                    v-model.number="bags"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    persistent-hint
                    :readonly="type == 'VIEW' ? true : false"
                    min="0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row> </v-row>
            </v-container>
          </v-form>
          <!-- View   -->
          <v-container class="px-0" v-if="type == 'VIEW'">
            <v-row v-for="(item, i) in questions" :key="item.id">
              <v-col>{{ item.question }}</v-col>
              <v-col
                ><label v-if="ans[i] == 'true'">Yes</label>
                <label v-if="ans[i] == 'false'">No</label></v-col
              >
            </v-row>
          </v-container>
          <!-- Create   -->
          <v-container class="px-0" v-if="type == 'CREATE'">
            <v-simple-table>
              <template v-slot:default>
                <tr v-for="(item, i) in questions" :key="item.id">
                  <td>{{ item.question }}</td>
                  <td>
                    <v-checkbox
                      v-model="ans[i]"
                      label="Yes"
                      color="green"
                      value="true"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="ans[i]"
                      label="No"
                      color="red"
                      hide-details
                      value="false"
                    ></v-checkbox>
                  </td>
                </tr>
              </template>
            </v-simple-table>
          </v-container>
          <v-container class="px-0">
            <v-row>
              <v-col cols="12" offset-md="8" md="4"
                ><v-btn
                  v-if="type == 'CREATE'"
                  @click="cancel"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    text-capitalize
                    btn-ls btn-secondary
                    bg-light
                    py-3
                    px-6
                    ml-2
                  "
                  >Cancel</v-btn
                >
                <v-btn
                  v-if="type == 'CREATE'"
                  @click="save"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    ml-2
                  "
                  >Save</v-btn
                >
                <!-- <v-btn
                  v-if="status == 'UPDATE'"
                  @click="save"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                    ml-2
                  "
                  >UPDATE</v-btn
                > -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
export default {
  data() {
    return {
      masterID: this.$route.params.data.id,

      vehicle_no: this.$route.params.data.vehicle_no,
      driver_contact_no: this.$route.params.data.driver_contact_no,
      mode_of_transport: this.$route.params.data.mode_of_transport,
      type: this.$route.params.type,
      ref: "",
      supplier_name: "",

      po_number: "",
      bill_no: "",

      bags: 0,

      overlay: false,
      questions: [],
      ans: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
    };
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.questions = await api.get();

      if (this.type == "VIEW") {
        let details = await api.getIrmcByDispatch(this.masterID);

        // this.status = "UPDATE";
        this.ref = details.ref;
        this.supplier_name = details.name_of_supplier;
        this.po_number = details.po_number;
        this.bill_no = details.bill_no;
        this.bags = details.number_of_bags;
        // console.log("details", details.irmc_child);

        this.questions.forEach((el) => {
          let i = details.irmc_child.findIndex((q) => {
            return q.irmc_question.id == el.id;
          });

          if (i > -1) {
            this.ans[i] = details.irmc_child[i].qc_status + "";
          }
        });
      }
      console.log("this.ans[i] ", this.ans, this.type);
    },
    back() {
      this.$router.push({ name: "IRMC" });
    },
    cancel() {
      this.ref = "";
      this.supplier_name = "";
      this.po_number = "";
      this.bill_no = "";
      this.vehicle_no = "";
      this.bags = 0;

      this.ans = [];
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },

    validate() {
      return this.$refs.frmSearch.validate();
    },
    resetValidation() {
      return this.$refs.frmSearch.resetValidation();
    },
    async save() {
      if (this.validate()) {
        let array = [];
        if (this.ans.flat().length == this.questions.length) {
          this.overlay = true;
          this.questions.forEach((el, i) => {
            let status = false;
            if (this.ans[i] == "false") {
              status = false;
            } else {
              status = true;
            }
            let d = {
              question: el,
              qc_status: status,
            };
            array.push(d);
          });
          let req = {
            ref: this.ref,
            incoming_date: new Date(this.date),
            name_of_supplier: this.supplier_name,
            po_number: this.po_number,
            bill_no: this.bill_no,
            vehicle_no: this.vehicle_no,
            number_of_bags: this.bags,
            irmc_child: array,
            dispacthMaster: this.masterID,
          };

          try {
            await api.saveData(req).then((res) => {});
            this.$router.push({ name: "IRMC" });
            this.overlay = false;
            this.showSuccessAlert("Data Saved");
            this.clear();
          } catch (error) {
            this.showErrorAlert(error);
          }
        } else {
          this.showWarningAlert("Please Answer All Question");
        }
      }
    },
    clear() {
      this.resetValidation();
      this.supplier_name = "";
      this.ref = "";
      this.po_number = "";
      this.bill_no = "";
      this.vehicle_no = "";
      this.bags = 0;
      this.overlay = false;

      this.ans = [];
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.mnu_date = false;
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: true,
        type: "error",

        icon: "error",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
  },
};
</script>

<style></style>
